<template>
    <div class="realName">
        <van-cell-group inset class="username">
            <van-field v-model="name" label="姓名" placeholder="请输入真实姓名" input-align="right" />
        </van-cell-group>

        <van-button type="primary" block round @click="onSubmit">确定</van-button>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { updRealname } from '@/api'
import { Toast } from 'vant'
import { GOPAY_USERINFO } from '@/assets/js/common.js'

export default {
    setup() {
        const state = reactive({
            name: ''
        })

        const router = useRouter()
        const store = useStore()

        const onSubmit = () => {
            updRealname(state.name).then(res => {
                if (res.status) {
                    localStorage.removeItem(GOPAY_USERINFO)
                    store.dispatch('getUserInfo').then(() => {
                        Toast.success(res.message)
                        router.push('/User')
                    })
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        return {
            ...toRefs(state),
            router,
            onSubmit
        }
    }
}
</script>

<style lang="scss" scoped>
.realName {
    box-sizing: border-box;
    padding: 0 w(10) h(50);

    .username {
        margin: h(15) 0 h(50);
    }
}
</style>
